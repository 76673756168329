import { __assign, __awaiter, __generator } from "tslib";
export var serializerMiddleware = function (options, serializer) {
    return function (next, context) {
        return function (args) { return __awaiter(void 0, void 0, void 0, function () {
            var request;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, serializer(args.input, options)];
                    case 1:
                        request = _a.sent();
                        return [2, next(__assign(__assign({}, args), { request: request }))];
                }
            });
        }); };
    };
};
