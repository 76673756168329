import { __awaiter, __generator } from "tslib";
import { GetCredentialsForIdentityCommand } from "@aws-sdk/client-cognito-identity";
import { CredentialsProviderError } from "@aws-sdk/property-provider";
import { resolveLogins } from "./resolveLogins";
export function fromCognitoIdentity(parameters) {
    var _this = this;
    return function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, _b, _c, AccessKeyId, Expiration, _d, SecretKey, SessionToken, _e, _f, _g, _h;
        var _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    _f = (_e = parameters.client).send;
                    _g = GetCredentialsForIdentityCommand.bind;
                    _j = {
                        CustomRoleArn: parameters.customRoleArn,
                        IdentityId: parameters.identityId
                    };
                    if (!parameters.logins) return [3, 2];
                    return [4, resolveLogins(parameters.logins)];
                case 1:
                    _h = _k.sent();
                    return [3, 3];
                case 2:
                    _h = undefined;
                    _k.label = 3;
                case 3: return [4, _f.apply(_e, [new (_g.apply(GetCredentialsForIdentityCommand, [void 0, (_j.Logins = _h,
                                _j)]))()])];
                case 4:
                    _a = (_k.sent()).Credentials, _b = _a === void 0 ? throwOnMissingCredentials() : _a, _c = _b.AccessKeyId, AccessKeyId = _c === void 0 ? throwOnMissingAccessKeyId() : _c, Expiration = _b.Expiration, _d = _b.SecretKey, SecretKey = _d === void 0 ? throwOnMissingSecretKey() : _d, SessionToken = _b.SessionToken;
                    return [2, {
                            identityId: parameters.identityId,
                            accessKeyId: AccessKeyId,
                            secretAccessKey: SecretKey,
                            sessionToken: SessionToken,
                            expiration: Expiration,
                        }];
            }
        });
    }); };
}
function throwOnMissingAccessKeyId() {
    throw new CredentialsProviderError("Response from Amazon Cognito contained no access key ID");
}
function throwOnMissingCredentials() {
    throw new CredentialsProviderError("Response from Amazon Cognito contained no credentials");
}
function throwOnMissingSecretKey() {
    throw new CredentialsProviderError("Response from Amazon Cognito contained no secret key");
}
