import { __assign, __extends } from "tslib";
import { CognitoIdentityServiceException as __BaseException } from "./CognitoIdentityServiceException";
export var AmbiguousRoleResolutionType;
(function (AmbiguousRoleResolutionType) {
    AmbiguousRoleResolutionType["AUTHENTICATED_ROLE"] = "AuthenticatedRole";
    AmbiguousRoleResolutionType["DENY"] = "Deny";
})(AmbiguousRoleResolutionType || (AmbiguousRoleResolutionType = {}));
export var CognitoIdentityProvider;
(function (CognitoIdentityProvider) {
    CognitoIdentityProvider.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CognitoIdentityProvider || (CognitoIdentityProvider = {}));
export var CreateIdentityPoolInput;
(function (CreateIdentityPoolInput) {
    CreateIdentityPoolInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateIdentityPoolInput || (CreateIdentityPoolInput = {}));
export var IdentityPool;
(function (IdentityPool) {
    IdentityPool.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(IdentityPool || (IdentityPool = {}));
var InternalErrorException = (function (_super) {
    __extends(InternalErrorException, _super);
    function InternalErrorException(opts) {
        var _this = _super.call(this, __assign({ name: "InternalErrorException", $fault: "server" }, opts)) || this;
        _this.name = "InternalErrorException";
        _this.$fault = "server";
        Object.setPrototypeOf(_this, InternalErrorException.prototype);
        return _this;
    }
    return InternalErrorException;
}(__BaseException));
export { InternalErrorException };
var InvalidParameterException = (function (_super) {
    __extends(InvalidParameterException, _super);
    function InvalidParameterException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidParameterException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidParameterException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidParameterException.prototype);
        return _this;
    }
    return InvalidParameterException;
}(__BaseException));
export { InvalidParameterException };
var LimitExceededException = (function (_super) {
    __extends(LimitExceededException, _super);
    function LimitExceededException(opts) {
        var _this = _super.call(this, __assign({ name: "LimitExceededException", $fault: "client" }, opts)) || this;
        _this.name = "LimitExceededException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, LimitExceededException.prototype);
        return _this;
    }
    return LimitExceededException;
}(__BaseException));
export { LimitExceededException };
var NotAuthorizedException = (function (_super) {
    __extends(NotAuthorizedException, _super);
    function NotAuthorizedException(opts) {
        var _this = _super.call(this, __assign({ name: "NotAuthorizedException", $fault: "client" }, opts)) || this;
        _this.name = "NotAuthorizedException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, NotAuthorizedException.prototype);
        return _this;
    }
    return NotAuthorizedException;
}(__BaseException));
export { NotAuthorizedException };
var ResourceConflictException = (function (_super) {
    __extends(ResourceConflictException, _super);
    function ResourceConflictException(opts) {
        var _this = _super.call(this, __assign({ name: "ResourceConflictException", $fault: "client" }, opts)) || this;
        _this.name = "ResourceConflictException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ResourceConflictException.prototype);
        return _this;
    }
    return ResourceConflictException;
}(__BaseException));
export { ResourceConflictException };
var TooManyRequestsException = (function (_super) {
    __extends(TooManyRequestsException, _super);
    function TooManyRequestsException(opts) {
        var _this = _super.call(this, __assign({ name: "TooManyRequestsException", $fault: "client" }, opts)) || this;
        _this.name = "TooManyRequestsException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, TooManyRequestsException.prototype);
        return _this;
    }
    return TooManyRequestsException;
}(__BaseException));
export { TooManyRequestsException };
export var DeleteIdentitiesInput;
(function (DeleteIdentitiesInput) {
    DeleteIdentitiesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteIdentitiesInput || (DeleteIdentitiesInput = {}));
export var ErrorCode;
(function (ErrorCode) {
    ErrorCode["ACCESS_DENIED"] = "AccessDenied";
    ErrorCode["INTERNAL_SERVER_ERROR"] = "InternalServerError";
})(ErrorCode || (ErrorCode = {}));
export var UnprocessedIdentityId;
(function (UnprocessedIdentityId) {
    UnprocessedIdentityId.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnprocessedIdentityId || (UnprocessedIdentityId = {}));
export var DeleteIdentitiesResponse;
(function (DeleteIdentitiesResponse) {
    DeleteIdentitiesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteIdentitiesResponse || (DeleteIdentitiesResponse = {}));
export var DeleteIdentityPoolInput;
(function (DeleteIdentityPoolInput) {
    DeleteIdentityPoolInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteIdentityPoolInput || (DeleteIdentityPoolInput = {}));
var ResourceNotFoundException = (function (_super) {
    __extends(ResourceNotFoundException, _super);
    function ResourceNotFoundException(opts) {
        var _this = _super.call(this, __assign({ name: "ResourceNotFoundException", $fault: "client" }, opts)) || this;
        _this.name = "ResourceNotFoundException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ResourceNotFoundException.prototype);
        return _this;
    }
    return ResourceNotFoundException;
}(__BaseException));
export { ResourceNotFoundException };
export var DescribeIdentityInput;
(function (DescribeIdentityInput) {
    DescribeIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeIdentityInput || (DescribeIdentityInput = {}));
export var IdentityDescription;
(function (IdentityDescription) {
    IdentityDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(IdentityDescription || (IdentityDescription = {}));
export var DescribeIdentityPoolInput;
(function (DescribeIdentityPoolInput) {
    DescribeIdentityPoolInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeIdentityPoolInput || (DescribeIdentityPoolInput = {}));
var ExternalServiceException = (function (_super) {
    __extends(ExternalServiceException, _super);
    function ExternalServiceException(opts) {
        var _this = _super.call(this, __assign({ name: "ExternalServiceException", $fault: "client" }, opts)) || this;
        _this.name = "ExternalServiceException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ExternalServiceException.prototype);
        return _this;
    }
    return ExternalServiceException;
}(__BaseException));
export { ExternalServiceException };
export var GetCredentialsForIdentityInput;
(function (GetCredentialsForIdentityInput) {
    GetCredentialsForIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetCredentialsForIdentityInput || (GetCredentialsForIdentityInput = {}));
export var Credentials;
(function (Credentials) {
    Credentials.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Credentials || (Credentials = {}));
export var GetCredentialsForIdentityResponse;
(function (GetCredentialsForIdentityResponse) {
    GetCredentialsForIdentityResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetCredentialsForIdentityResponse || (GetCredentialsForIdentityResponse = {}));
var InvalidIdentityPoolConfigurationException = (function (_super) {
    __extends(InvalidIdentityPoolConfigurationException, _super);
    function InvalidIdentityPoolConfigurationException(opts) {
        var _this = _super.call(this, __assign({ name: "InvalidIdentityPoolConfigurationException", $fault: "client" }, opts)) || this;
        _this.name = "InvalidIdentityPoolConfigurationException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, InvalidIdentityPoolConfigurationException.prototype);
        return _this;
    }
    return InvalidIdentityPoolConfigurationException;
}(__BaseException));
export { InvalidIdentityPoolConfigurationException };
export var GetIdInput;
(function (GetIdInput) {
    GetIdInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetIdInput || (GetIdInput = {}));
export var GetIdResponse;
(function (GetIdResponse) {
    GetIdResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetIdResponse || (GetIdResponse = {}));
export var GetIdentityPoolRolesInput;
(function (GetIdentityPoolRolesInput) {
    GetIdentityPoolRolesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetIdentityPoolRolesInput || (GetIdentityPoolRolesInput = {}));
export var MappingRuleMatchType;
(function (MappingRuleMatchType) {
    MappingRuleMatchType["CONTAINS"] = "Contains";
    MappingRuleMatchType["EQUALS"] = "Equals";
    MappingRuleMatchType["NOT_EQUAL"] = "NotEqual";
    MappingRuleMatchType["STARTS_WITH"] = "StartsWith";
})(MappingRuleMatchType || (MappingRuleMatchType = {}));
export var MappingRule;
(function (MappingRule) {
    MappingRule.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MappingRule || (MappingRule = {}));
export var RulesConfigurationType;
(function (RulesConfigurationType) {
    RulesConfigurationType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RulesConfigurationType || (RulesConfigurationType = {}));
export var RoleMappingType;
(function (RoleMappingType) {
    RoleMappingType["RULES"] = "Rules";
    RoleMappingType["TOKEN"] = "Token";
})(RoleMappingType || (RoleMappingType = {}));
export var RoleMapping;
(function (RoleMapping) {
    RoleMapping.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RoleMapping || (RoleMapping = {}));
export var GetIdentityPoolRolesResponse;
(function (GetIdentityPoolRolesResponse) {
    GetIdentityPoolRolesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetIdentityPoolRolesResponse || (GetIdentityPoolRolesResponse = {}));
export var GetOpenIdTokenInput;
(function (GetOpenIdTokenInput) {
    GetOpenIdTokenInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetOpenIdTokenInput || (GetOpenIdTokenInput = {}));
export var GetOpenIdTokenResponse;
(function (GetOpenIdTokenResponse) {
    GetOpenIdTokenResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetOpenIdTokenResponse || (GetOpenIdTokenResponse = {}));
var DeveloperUserAlreadyRegisteredException = (function (_super) {
    __extends(DeveloperUserAlreadyRegisteredException, _super);
    function DeveloperUserAlreadyRegisteredException(opts) {
        var _this = _super.call(this, __assign({ name: "DeveloperUserAlreadyRegisteredException", $fault: "client" }, opts)) || this;
        _this.name = "DeveloperUserAlreadyRegisteredException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, DeveloperUserAlreadyRegisteredException.prototype);
        return _this;
    }
    return DeveloperUserAlreadyRegisteredException;
}(__BaseException));
export { DeveloperUserAlreadyRegisteredException };
export var GetOpenIdTokenForDeveloperIdentityInput;
(function (GetOpenIdTokenForDeveloperIdentityInput) {
    GetOpenIdTokenForDeveloperIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetOpenIdTokenForDeveloperIdentityInput || (GetOpenIdTokenForDeveloperIdentityInput = {}));
export var GetOpenIdTokenForDeveloperIdentityResponse;
(function (GetOpenIdTokenForDeveloperIdentityResponse) {
    GetOpenIdTokenForDeveloperIdentityResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetOpenIdTokenForDeveloperIdentityResponse || (GetOpenIdTokenForDeveloperIdentityResponse = {}));
export var GetPrincipalTagAttributeMapInput;
(function (GetPrincipalTagAttributeMapInput) {
    GetPrincipalTagAttributeMapInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetPrincipalTagAttributeMapInput || (GetPrincipalTagAttributeMapInput = {}));
export var GetPrincipalTagAttributeMapResponse;
(function (GetPrincipalTagAttributeMapResponse) {
    GetPrincipalTagAttributeMapResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetPrincipalTagAttributeMapResponse || (GetPrincipalTagAttributeMapResponse = {}));
export var ListIdentitiesInput;
(function (ListIdentitiesInput) {
    ListIdentitiesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListIdentitiesInput || (ListIdentitiesInput = {}));
export var ListIdentitiesResponse;
(function (ListIdentitiesResponse) {
    ListIdentitiesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListIdentitiesResponse || (ListIdentitiesResponse = {}));
export var ListIdentityPoolsInput;
(function (ListIdentityPoolsInput) {
    ListIdentityPoolsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListIdentityPoolsInput || (ListIdentityPoolsInput = {}));
export var IdentityPoolShortDescription;
(function (IdentityPoolShortDescription) {
    IdentityPoolShortDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(IdentityPoolShortDescription || (IdentityPoolShortDescription = {}));
export var ListIdentityPoolsResponse;
(function (ListIdentityPoolsResponse) {
    ListIdentityPoolsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListIdentityPoolsResponse || (ListIdentityPoolsResponse = {}));
export var ListTagsForResourceInput;
(function (ListTagsForResourceInput) {
    ListTagsForResourceInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceInput || (ListTagsForResourceInput = {}));
export var ListTagsForResourceResponse;
(function (ListTagsForResourceResponse) {
    ListTagsForResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceResponse || (ListTagsForResourceResponse = {}));
export var LookupDeveloperIdentityInput;
(function (LookupDeveloperIdentityInput) {
    LookupDeveloperIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LookupDeveloperIdentityInput || (LookupDeveloperIdentityInput = {}));
export var LookupDeveloperIdentityResponse;
(function (LookupDeveloperIdentityResponse) {
    LookupDeveloperIdentityResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LookupDeveloperIdentityResponse || (LookupDeveloperIdentityResponse = {}));
export var MergeDeveloperIdentitiesInput;
(function (MergeDeveloperIdentitiesInput) {
    MergeDeveloperIdentitiesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MergeDeveloperIdentitiesInput || (MergeDeveloperIdentitiesInput = {}));
export var MergeDeveloperIdentitiesResponse;
(function (MergeDeveloperIdentitiesResponse) {
    MergeDeveloperIdentitiesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MergeDeveloperIdentitiesResponse || (MergeDeveloperIdentitiesResponse = {}));
var ConcurrentModificationException = (function (_super) {
    __extends(ConcurrentModificationException, _super);
    function ConcurrentModificationException(opts) {
        var _this = _super.call(this, __assign({ name: "ConcurrentModificationException", $fault: "client" }, opts)) || this;
        _this.name = "ConcurrentModificationException";
        _this.$fault = "client";
        Object.setPrototypeOf(_this, ConcurrentModificationException.prototype);
        return _this;
    }
    return ConcurrentModificationException;
}(__BaseException));
export { ConcurrentModificationException };
export var SetIdentityPoolRolesInput;
(function (SetIdentityPoolRolesInput) {
    SetIdentityPoolRolesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetIdentityPoolRolesInput || (SetIdentityPoolRolesInput = {}));
export var SetPrincipalTagAttributeMapInput;
(function (SetPrincipalTagAttributeMapInput) {
    SetPrincipalTagAttributeMapInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetPrincipalTagAttributeMapInput || (SetPrincipalTagAttributeMapInput = {}));
export var SetPrincipalTagAttributeMapResponse;
(function (SetPrincipalTagAttributeMapResponse) {
    SetPrincipalTagAttributeMapResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetPrincipalTagAttributeMapResponse || (SetPrincipalTagAttributeMapResponse = {}));
export var TagResourceInput;
(function (TagResourceInput) {
    TagResourceInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagResourceInput || (TagResourceInput = {}));
export var TagResourceResponse;
(function (TagResourceResponse) {
    TagResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagResourceResponse || (TagResourceResponse = {}));
export var UnlinkDeveloperIdentityInput;
(function (UnlinkDeveloperIdentityInput) {
    UnlinkDeveloperIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnlinkDeveloperIdentityInput || (UnlinkDeveloperIdentityInput = {}));
export var UnlinkIdentityInput;
(function (UnlinkIdentityInput) {
    UnlinkIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnlinkIdentityInput || (UnlinkIdentityInput = {}));
export var UntagResourceInput;
(function (UntagResourceInput) {
    UntagResourceInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UntagResourceInput || (UntagResourceInput = {}));
export var UntagResourceResponse;
(function (UntagResourceResponse) {
    UntagResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UntagResourceResponse || (UntagResourceResponse = {}));
